import store from '@/store'
import { ref, watch, computed } from '@vue/composition-api'

export default function useProjectsNew() {

  const budgetSourceTypesList = ref([])
  const budgetTypesList = ref([])
  const mails = ref([])
  const geographiesList = ref([])
  const linesList = ref([])
  const categoriesList = ref([])
  const originsList = ref([])
  const populationsList = ref([])
  const sectorsList = ref([])
  const subSectorsList = ref([])
  const statusList = ref([])
  const thirdPartiesList = ref([])
  const typeList = ref([])
  const umList = ref([])
  const userList = ref([])
  const zonesList = ref([])
  const loading = ref(false)

  const budgetColumns = [
    { text: '#ITEM', value: 'item', sortable: false},
    { text: 'ORIGEN', value: 'origin', width: '400px', sortable: false },
    { text: 'TIPO DE FINANCIACIÓN', value: 'budget_source_type', width: '200px', sortable: false },
    { text: 'NATURALEZA', value: 'budget_type', width: '200px', sortable: false },
    { text: 'PRESUPUESTO', value: 'budget', sortable: false, width: '300px' },
    { text: 'ESTADO', value: 'stated_at', sortable: false},
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]
  const costColumns = [
    { text: '#ITEM', value: 'item', sortable: false},
    { text: 'DESCRIPCIÓN', value: 'description', width: '400px', sortable: false },
    { text: 'VALOR EJECUTADO', value: 'value', width: '200px', sortable: false },
    { text: 'ESTADO', value: 'stated_at', sortable: false},
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const editorOption = {
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'], // negrita, cursiva, subrayado, tachado
        ['blockquote', 'code-block'], // cotización, bloque de código
        [{'header': 1}, {'header': 2}], // Título, en forma de pares clave-valor; 1, 2 significa tamaño de fuente
        [{ 'list': 'ordered'}, { 'list': 'bullet' }], // lista
        [{'script': 'sub'}, {'script': 'super'}], // subíndice y subíndice
        [{ 'indent': '-1'}, { 'indent': '+1' }], // sangría
        // dirección del texto
        //[{ 'size': ['small', false, 'large', 'huge'] }], tamaño de fuente
        [{'header': [1, 2, 3, 4, 5, 6, false]}], // Varios niveles de encabezado
        [{'color': []}, {'background': []}], // color de fuente, color de fondo de fuente
        [{'font': []}], // fuente
        [{'align': []}], // Alineación
        ['clean'],
        ['link'] // Borrar estilo de fuente
      ]
    },
    placeholder: "Ingresar contenido Objetivo"
  } // Elementos de configuración del editor

  //create
  const create = async payload => {
    try {
      const response = await store.dispatch('projects/create', payload)
      const call = configModal(payload.model).function
      await call()
      return response
    } catch (error) {

    }
  }

  const createProject = async payload => {
    try {
      const response = await store.dispatch('projects/createProject', payload)
      return response
    } catch (error) {

    }
  }

  const createProjectFormulations = async payload => {
    try {
      const response = await store.dispatch('projects/createProjectFormulations', payload)
      return response
    } catch (error) {

    }
  }

  const updateProject = async payload => {
    try {
      const response = await store.dispatch('projects/updateProject', payload)
      return response
    } catch (error) {

    }
  }
  const updateProjectFormulations = async payload => {
    try {
      const response = await store.dispatch('projects/updateProjectFormulations', payload)
      return response
    } catch (error) {

    }
  }

  const setOne = async payload => {
    try {
      const response = await store.dispatch('projects/setOne', payload)
      return response.object
    } catch (error) {

    }
  }

  //update
  const update = async payload => {
    try {
      const response = await store.dispatch('projects/update', payload)
      const call = configModal(payload.model).function
      await call()
      return response
    } catch (error) {

    }
  }

  // fetch data
  const fetchCategories = async () => {
    const response = await store.dispatch('projects/fetchList', 'line_categories')
      categoriesList.value = response.object
  }
  const fetchGeographies = async () => {
    const response = await store.dispatch('projects/fetchList', 'geographies')
      geographiesList.value = response.object
  }
  const fetchLines = async () => {
    const response = await store.dispatch('projects/fetchList', 'lines')
      linesList.value = response.object
  }
  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('projects/fetchOne', id)
      return res.object
    } catch (error) {
    }
  }
  const fetchOneFormulations = async (id) => {
    try {
      const res = await store
      .dispatch('projects/fetchOneFormulations', id)
      return res.object
    } catch (error) {
    }
  }
  const fetchProjectBudgets = () => {
    store.dispatch('projects/fetchList', 'project_budgets')
    .then(response => {

    })
  }
  const fetchProjectStatus = async () => {
    const response = await store.dispatch('projects/fetchList', 'project_statuses')
      statusList.value = response.object
  }
  const fetchBudgetSourceTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'budget_source_types')
      budgetSourceTypesList.value = response.object
  }
  const fetchBudgetTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'budget_types')
      budgetTypesList.value = response.object
  }
  const fetchProjectTypes = async () => {
    const response = await store.dispatch('projects/fetchList', 'project_types')
      typeList.value = response.object
  }
  const fetchOrigins = async () => {
    const response = await store.dispatch('projects/fetchList', 'origins')
      originsList.value = response.object
  }
  const fetchPopulations = async () => {
    const response = await store.dispatch('projects/fetchList', 'populations')
      populationsList.value = response.object
  }
  const fetchSectors = async () => {
    const response = await store.dispatch('projects/fetchList', 'sectors')
      sectorsList.value = response.object
  }

  const fetchSelectLists = async () => {
    const {
      budget_source_types,
      budget_types,
      emails,
      geographies,
      line_categories,
      lines,
      origins,
      populations,
      project_budgets,
      project_statuses,
      project_types,
      sectors,
      subsectors,
      third_parties,
      unit_measurements,
      users,
      zones } = await store.dispatch('projects/fetchSelectLists')

      budgetSourceTypesList.value = budget_source_types
      budgetTypesList.value = budget_types
      mails.value = emails
      geographiesList.value = geographies
      categoriesList.value = line_categories
      linesList.value = lines
      originsList.value = origins
      populationsList.value = populations
      statusList.value = project_statuses
      typeList.value = project_types
      sectorsList.value = sectors
      subSectorsList.value = subsectors
      thirdPartiesList.value = third_parties
      umList.value = unit_measurements
      userList.value = users.map(user => {
        const roles = user.roles.map(rol => rol.role)
        return {
          ...user,
          roles: roles,
          value: user.id,
        }
      })
      zonesList.value = zones
  }
  const fetchSubSectors = async () => {
    const response = await store.dispatch('projects/fetchList', 'subsectors')
      subSectorsList.value = response.object
  }
  const fetchThirdParties = async () => {
    const response = await store.dispatch('projects/fetchList', 'third_parties')
      thirdPartiesList.value = response.object
  }
  const fetchUM = async () => {
    const response = await store.dispatch('projects/fetchList', 'unit_measurements')
      umList.value = response
  }
  const fetchZones = async () => {
    const response = await store.dispatch('projects/fetchList', 'zones')
      zonesList.value = response.object
  }

  const fetchUsers = () => {
    store
      .dispatch('users/fetchUsers')
      .then(response => {
        const data = ref([])
        if (response) data.value =  response.map(ele => {
          return JSON.parse(ele['listusers']).users
        })

        userList.value = data.value[0].map(user => {
          const roles = user.roles.map(rol => rol.role)
          return {
            ...user,
            roles: roles,
            value: user.id,
          }
        })

        // remove loading state
      })
  }


  const selectList = (array) => {
    const list = ref([])
    if (array.value) {
      list.value = array.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
          code: type.code
        }
        return ele
      })
    }
    return list.value
  }

  const selectBudgetsList = array => {
    const list = ref([])
    if (array.value) {
      list.value = array.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
        }
        return ele
      })
    }
    return list.value
  }

  const selectGeographiesList = computed(() => {
    const list = ref([])
    if (geographiesList.value) {
      list.value = geographiesList.value
      .map(type => {
        const ele = {
          value: type.id,
          text: type.ancestries ? type.ancestries + ', ' + type.description :  type.description,
        }
        return ele
      })
    }
    return list.value
  })
  const selectBudgetSourceTypesList = computed(() => {
    return selectBudgetsList(budgetSourceTypesList)
  })
  const selectBudgetTypesList = computed(() => {
    return selectBudgetsList(budgetTypesList)
  })
  const selectCategoriesList = computed(() => {
    const list = ref([])
    if (categoriesList.value) {
      list.value = categoriesList.value
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
        }
        return ele
      })
    }
    return list.value
  })
  const selectLinesList = computed(() => {
    const list = ref([])
    if (linesList.value) {
      linesList.value
      .forEach(type => {
        type.lines.forEach(line => {
          const ele = {
            line_category_id: line.line_category_id,
            value: line.id,
            text: line.name_of,
            code: line.code
          }
          list.value.push(ele)
        })
      })
    }
    return list.value
  })
  const selectLinesCategoriesList = computed(() => {
    const list = ref([])
    if (linesList.value) {
      linesList.value
      .forEach((type, i) => {
        if (i !== 0) {
          list.value.push({ divider: true })
        }
        const header = { header: type.line_category_name_of }
        list.value.push(header)
        type.lines.forEach(line => {
          if (line.stated_at){
            const ele = {
              id_category: line.line_category_id,
              value: line.id,
              title_category: line.name_of,
              active: false,
              stated_at: line.stated_at
            }
            list.value.push(ele)
          }
        })

      })
    }
    return list.value
  })
  const selectEmails = computed(() => {
    return mails.value
  })
  const selectStatusList = computed(() => {
    const list = ref([])
    if (statusList.value) {
      list.value = statusList.value.filter(item => item.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of,
          type: type.type_project_status
        }
        return ele
      })
    }
    return list.value
  })
  const selectOriginsList = computed(() => {
    return selectList(originsList)
  })
  const selectPopulationsList = computed(() => {
    return selectList(populationsList)
  })
  const selectSectorsList = computed(() => {
    return selectList(sectorsList)
  })
  const selectSubSectorsList = computed(() => {
    return selectList(subSectorsList)
  })
  const selectThirdPartiesList = computed(() => {
    const list = ref([])
    if (thirdPartiesList.value) {
      list.value = thirdPartiesList.value.filter( third => third.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.full_name,
          identification: type.identification,
          full_name: type.full_name,
        }
        return ele
      })
    }
    return list.value
  })
  const selectUMList = computed(() => {
    const list = ref([])
    if (umList.value) {
      list.value = umList.value.filter( um => um.stated_at)
      .map(type => {
        const ele = {
          value: type.id,
          text: type.name_of
        }
        return ele
      })
    }
    return list.value
  })
  const selectTypeList = computed(() => {
    return selectList(typeList)
  })
  const selectZonesList = computed(() => {
    return selectList(zonesList)
  })

  const configModal = key => {
    if (key === 'project_types')
    return {
      title: 'Tipo de Proyecto',
      select_list: selectTypeList,
      model: 'project_types',
      keyName: 'project_type',
      function: fetchProjectTypes
    }
    if (key === 'origins')
    return {
      title: 'Rol CCB',
      select_list: selectOriginsList,
      model: 'origins',
      keyName: 'origin',
      function: fetchOrigins
    }
    if (key === 'project_statuses')
    return {
      title: 'Estado',
      select_list: selectStatusList,
      model: 'project_statuses',
      keyName: 'project_status',
      function: fetchProjectStatus
    }
    if (key === 'zones')
    return {
      title: 'Vicepresidencias',
      select_list: selectZonesList,
      model: 'zones',
      keyName: 'zone',
      function: fetchZones
    }
    if (key === 'populations')
    return {
      title: 'Población Beneficiada',
      select_list: selectPopulationsList,
      model: 'populations',
      keyName: 'population',
      function: fetchPopulations
    }
    if (key === 'lines' || key === 'line_categories')
    return {
      title: 'Linea',
      select_list: selectLinesList,
      select_list2: selectCategoriesList.value,
      model: 'lines',
      model2: 'line_categories',
      keyName: 'line',
      keyName2: 'line_category',
      function: fetchLines
    }
    if (key === 'sectors')
    return {
      title: 'Sector',
      select_list: selectSectorsList,
      model: 'sectors',
      keyName: 'sector',
      function: fetchSectors
    }
    if (key === 'subsectors')
    return {
      title: 'Sub Sector',
      select_list: selectSubSectorsList,
      model: 'subsectors',
      keyName: 'subsector',
      function: fetchSubSectors
    }
    if (key === 'third_parties')
    return {
      title: 'Terceros',
      select_list: selectThirdPartiesList,
      model: 'third_parties',
      keyName: 'third_party',
      function: fetchThirdParties
    }
    if (key === 'budget_source_types')
    return {
      title: 'Tipo de Financiación',
      select_list: selectBudgetSourceTypesList,
      model: 'budget_source_types',
      keyName: 'budget_source_type',
      function: fetchBudgetSourceTypes
    }
    if (key === 'budget_types')
    return {
      title: 'Tipo Presupuesto',
      select_list: selectBudgetTypesList,
      model: 'budget_types',
      keyName: 'budget_type',
      function: fetchBudgetTypes
    }
  }


  return {
    budgetColumns,
    costColumns,
    create,
    createProject,
    createProjectFormulations,
    configModal,
    editorOption,
    fetchCategories,
    fetchBudgetSourceTypes,
    fetchBudgetTypes,
    fetchGeographies,
    fetchLines,
    fetchOne,
    fetchOneFormulations,
    fetchOrigins,
    fetchProjectBudgets,
    fetchProjectStatus,
    fetchProjectTypes,
    fetchPopulations,
    fetchSectors,
    fetchSelectLists,
    fetchSubSectors,
    fetchThirdParties,
    fetchUM,
    fetchZones,
    fetchUsers,
    loading,
    selectBudgetSourceTypesList,
    selectBudgetTypesList,
    selectCategoriesList,
    selectEmails,
    selectGeographiesList,
    selectLinesList,
    selectLinesCategoriesList,
    selectOriginsList,
    selectStatusList,
    selectPopulationsList,
    selectSectorsList,
    selectSubSectorsList,
    selectThirdPartiesList,
    selectTypeList,
    selectUMList,
    selectZonesList,
    setOne,
    update,
    updateProject,
    updateProjectFormulations,
    userList
  }
}
