import store from '@/store'
import {
  mdiAccountOutline,
  mdiFolder,
  mdiFolderEdit,
  mdiFolderAccount,
  mdiFolderRemove,
  mdiFolderOutline,
} from '@mdi/js'
import { ref, computed } from '@vue/composition-api'

export default function useProjectsList() {

  const tableColumns = [
    { text: 'PROYECTO', value: 'description', width: 350, cellClass: 'py-2' },
    { text: 'RESPONSABLE', value: 'project_manager' },
    { text: 'INICIO', value: 'date_entry', width: 130 },
    { text: 'FIN', value: 'date_end', width: 130 },
    { text: 'PROGRESO', value: 'progress', width: 120 },
    { text: 'ESFUERZO', value: 'effort', width: 120 },
    { text: 'PRESUPUESTO', value: 'budget'},
    { text: 'EJECUCIÓN', value: 'execution',width: 120 },
    { text: 'ESTADO', value: 'status' },
    {
      text: 'ACCIONES',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const filterLists = ref([])
  const projectTotalLocal = ref([])
  const projectList = ref([])


  const filterFields = [
    {text: 'PROYECTO', value: 'description', type: 'text'},
    {text: 'RESPONSABLE', value: 'project_manager', type: 'text'},
    {text: 'INICIO', value: 'date_entry', type: 'date'},
    {text: 'FIN', value: 'date_end', type: 'date'},
    {text: 'PROGRESO', value: 'progress', type: 'number'},
    {text: 'ESFUERZO', value: 'effort', type: 'number'},
    {text: 'PRESUPUESTO', value: 'budget', type: 'number'},
    {text: 'EJECUCIÓN', value: 'execution', type: 'number'},
    {text: 'ESTADO', value: 'status', type: 'text'}
  ]

  const options = ref({
    sortBy: ['date_entry'],
    sortDesc: [true],
  })


  // fetch data
  const fetchOne = async (id) => {
    try {
      const res = await store
      .dispatch('projects/fetchOne', id)
      return res
    } catch (error) {
    }
  }

  const fetchProjectReport = async (id) => {
    try {
      const res = await store
      .dispatch('projects/fetchProjectReport', id)
      return res
    } catch (error) {
    }
  }

  const fetchProjectTypes = () => {
    store
      .dispatch('projects/fetchProjectsType')
  }

  const fetchProjectList = async () => {
    let data = await store.dispatch('projects/fetchProjectList')
    const totales = data.totales
    const { total_project, total_project_committee, total_project_ideation, total_project_rejected } = totales

        let resumen_project = totales.resumen_project
        projectTotalLocal.value = [
          { title: 'Total Proyectos', total: total_project },
          { title: 'En ideación', total: total_project_ideation },
          { title: 'En Comité', total: total_project_committee },
          { title: 'Rechazados', total: total_project_rejected },
        ]
        projectList.value = resumen_project

      return resumen_project
  }

  const updateProject = async payload => {
    try {
      const response = await store.dispatch('projects/updateProject', payload)
      return response
    } catch (error) {

    }
  }


  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*


  const resolveStatusIcon = status => {
    if (status === 'ideation' || status === 'Ideacion') return 'ideation.png'
    if (status === 'Comite evaluativo' || status === 'committe') return 'committee.png'
    if (status === 'Ejecucion' || status === 'ejecution') return 'ejecution.png'
    if (status === 'Formulacion de proyectos' || status === 'formulation') return 'formulation.png'
    if (status === 'admin') return mdiDnsOutline

    return mdiAccountOutline
  }

  const resolveStatusVariant = status => {
    if (status === 'ideation' || status === 'Ideacion') return '#FFEBEE'
    if (status === 'committe') return '#FFF3E0'
    if (status === 'Formulacion de proyectos' || status === 'formulation') return '#FFFDE7'
    if (status === 'Ejecucion' || status === 'ejecution') return '#E8F5E9'

    return 'primary'
  }

  const resolveStatusText = status => {
    if (status === 'Ideacion' || status === 'ideation') return 'Ideación'
    if (status === 'Comite evaluativo' || status === 'committe') return 'Comité evaluativo'
    if (status === 'Formulacion de proyectos' || status === 'formulation') return 'Formulación de proyectos'
    if (status === 'Ejecucion' || status == 'ejecution') return 'Ejecución'

    return 'Revisar api'
  }



  const resolveProjectTotalIcon = total => {
    if (total === 'Total Proyectos') return { icon: mdiFolder, color: 'primary' }
    if (total === 'En ideación') return { icon: mdiFolderEdit, color: 'success' }
    if (total === 'En Comité') return { icon: mdiFolderAccount, color: 'warning' }
    if (total === 'Rechazados') return { icon: mdiFolderRemove, color: 'error' }

    return { icon: mdiFolderOutline, color: 'primary' }
  }

  const selectProjectsList = computed(() => {
    const list = ref([])
    if (projectList.value) {
      list.value = projectList.value
      .map(type => {
        const ele = {
          value: type.id,
          text: type.description
        }
        return ele
      })
    }
    return list.value
  })


  return {
    filterLists,
    filterFields,
    tableColumns,
    options,
    fetchOne,
    fetchProjectList,
    fetchProjectTypes,
    fetchProjectReport,
    projectTotalLocal,
    projectList,
    resolveStatusText,
    resolveStatusVariant,
    resolveStatusIcon,
    resolveProjectTotalIcon,
    selectProjectsList,
    updateProject
  }
}
